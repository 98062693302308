import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Card, CardActions, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { formatTimestamp, TIME_FORMAT_HUMAN_READABLE_SHORT } from '../../../helpers/time';
import Manager from '../../../components/Workflow/Manager';
import StatusTag from '../../../components/StatusTag';
import { getColorByStatus } from '../../../helpers';
import JawLabels from './TrayWorkflow/JawLabels';
import TrayFilesManager from './TrayWorkflow/TrayFilesManager';
import {
  fetchTrayDesignRequestAction,
  updateTrayDesignRequestAction
} from '../../../reducers/trayDesignRequests';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { JAWS_TYPES_IDS } from '../../../constants';
import hasPermission from '../../../selectors/hasPermission';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import { cancelRequestAction, completeRequestAction } from '../../../reducers/requests';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    padding: theme.spacing(1, 2),
    alignItems: 'baseline'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const TrayDesignRequestWorkflow = ({
  fetch,
  fetching,
  data,
  techWorkflowId,
  update,
  updating,
  readOnly,
  cancel,
  complete
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (fetching || data) {
      return;
    }
    fetch();
  }, [fetch, fetching, data]);

  if (!data) {
    return <LoadingIndicator />;
  }

  const createdAt = formatTimestamp(data.created_at);
  const updatedAt = formatTimestamp(data.updated_at, { format: TIME_FORMAT_HUMAN_READABLE_SHORT });
  const status = data.status_label;
  const isCancelledOrCompleted =
    data.status_label === 'Canceled' || data.status_label === 'Completed';

  const arches = [
    {
      type: JAWS_TYPES_IDS.maxillary,
      fileId: data.maxillary_file_id
    },
    {
      type: JAWS_TYPES_IDS.mandibular,
      fileId: data.mandibular_file_id
    }
  ].filter(({ type }) => type === data.jaw_type || data.jaw_type === JAWS_TYPES_IDS.both);

  const uploadHandler = (type, file) => {
    const prefix = type === JAWS_TYPES_IDS.maxillary ? 'maxillary' : 'mandibular';
    const updatedData = {
      maxillary_file_id: data.maxillary_file_id,
      mandibular_file_id: data.mandibular_file_id,
      [`${prefix}_file_id`]: file.id
    };
    update(updatedData);
  };

  return (
    <div>
      <Box mt={2} width="100%">
        <Card>
          <div>
            <Grid container className={classes.header}>
              <Grid item xs={12} md={6}>
                <Box display="flex" alignItems="center">
                  <Typography variant="h5" style={{ display: 'inline-block' }}>
                    Tray Design Request
                  </Typography>
                  {!!status && <StatusTag label={status} color={getColorByStatus(status)} />}
                </Box>
              </Grid>
              {!!createdAt && (
                <Grid item xs={12} md={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Typography variant="caption">
                      Requested at {createdAt} (Updated: {updatedAt})
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </div>

          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <JawLabels jaws={arches} />
              </Grid>
              <Grid item xs={8}>
                <Manager
                  id={techWorkflowId}
                  type="tray_design_request"
                  afterUpdate={fetch}
                  readOnly={readOnly || isCancelledOrCompleted}
                />
              </Grid>
              <Grid item xs={2}>
                <TrayFilesManager
                  disabled={updating}
                  patientId={data.patient_id}
                  jaws={arches}
                  onDelete={() => fetch()}
                  onUpload={uploadHandler}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            {!readOnly && !isCancelledOrCompleted && (
              <>
                <ConfirmationDialogOnClickWrapper
                  confirmationTitle="Cancel Request"
                  confirmationBody="Are you sure you want to cancel this request?"
                >
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={() => cancel()}
                  >
                    Cancel
                  </Button>
                </ConfirmationDialogOnClickWrapper>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    complete();
                    fetch();
                  }}
                >
                  Complete
                </Button>
              </>
            )}
          </CardActions>
        </Card>
      </Box>
    </div>
  );
};

TrayDesignRequestWorkflow.propTypes = {
  fetch: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    patient_id: PropTypes.number.isRequired,
    jaw_type: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    status_label: PropTypes.string.isRequired,
    maxillary_file_id: PropTypes.number,
    mandibular_file_id: PropTypes.number
  }),
  techWorkflowId: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  cancel: PropTypes.func.isRequired,
  complete: PropTypes.func.isRequired
};

TrayDesignRequestWorkflow.defaultProps = {
  data: null,
  readOnly: true
};

export default connect(
  (state, { id }) => {
    const { data = null, fetching = false, updating = false } =
      state.trayDesignRequests.current || {};
    return {
      data: data !== null && id === data.id ? data : null,
      fetching,
      updating,
      readOnly: !hasPermission(state, { permissions: ['tray-design-requests.manage'] })
    };
  },
  (dispatch, props) => {
    const fetch = () => dispatch(fetchTrayDesignRequestAction(props.id));

    return {
      fetch,
      update: data => dispatch(updateTrayDesignRequestAction(props.id, data)),
      complete: () => dispatch(completeRequestAction(props.customerRequestId)).then(fetch),
      cancel: () => dispatch(cancelRequestAction(props.customerRequestId)).then(fetch)
    };
  }
)(TrayDesignRequestWorkflow);
