import {
  ARCH_OPTIONS,
  ASSIGNMENT_LABELS,
  ASSIGNMENTS,
  ATTRIBUTES,
  EXPORT_LABELS,
  EXPORTS,
  QUESTIONS
} from '../../../../components/RxForm/constants';
import { assignmentOptions } from '../../../../components/RxForm/helpers';
import { generateAllowedDIBSOptions, generateAssignmentOptions } from './functions';

const { ORTHOSELECT, THIRD_PARTY, IN_OFFICE } = ASSIGNMENTS;

export default [
  {
    label: QUESTIONS[ATTRIBUTES.TREATMENT_LOCATION],
    name: 'treatment_location_id',
    options: ({ metadata: { locations } }) => {
      return locations.map(({ id: value, name: label }) => ({ value, label }));
    },
    emptyOptionsLabel: 'No location found'
  },
  {
    label: QUESTIONS[ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT],
    name: 'initial_case_setup_assignment_options',
    options: ({ metadata }) => {
      return generateAssignmentOptions(metadata.third_party_partner.name);
    },
    encodeValue: true
  },
  {
    name: 'rush_case_enabled',
    options: () => {
      return [
        { value: false, label: 'No' },
        { value: true, label: 'Yes' }
      ];
    },
    onChangeHook: (current, changes) => {
      if (current.rush_case_enabled && current.rush_case) {
        return { ...changes, rush_case_enabled: true, rush_case: false };
      }
      return changes;
    }
  },
  {
    name: 'rush_case',
    options: () => {
      return [
        { value: false, label: 'No' },
        { value: true, label: 'Yes' }
      ];
    },
    isDisabled: current => !current.rush_case_enabled
  },
  {
    label: QUESTIONS[ATTRIBUTES.SCAN_SUBMISSION_METHOD],
    name: 'scan_submission_options',
    options: () => {
      return [
        { value: [1], label: 'Via File Upload' },
        { value: [1, 2], label: 'Via File Upload or Via Scanner' }
      ];
    },
    encodeValue: true
  },
  {
    label: QUESTIONS[ATTRIBUTES.ARCH_TYPE],
    name: 'arch_type',
    options: () => ARCH_OPTIONS
  },
  {
    name: 'export_type_options',
    label: QUESTIONS[ATTRIBUTES.EXPORT_TYPE],
    options: () => [
      {
        value: [EXPORTS.TRAYS],
        label: EXPORT_LABELS[EXPORTS.TRAYS]
      },
      {
        value: [EXPORTS.GUIDES],
        label: EXPORT_LABELS[EXPORTS.GUIDES]
      },
      {
        value: [EXPORTS.BRACKETS],
        label: EXPORT_LABELS[EXPORTS.BRACKETS]
      },
      {
        value: [EXPORTS.TRAYS, EXPORTS.GUIDES, EXPORTS.BRACKETS],
        label: 'Show All Options'
      }
    ],
    encodeValue: true
  },
  {
    label: QUESTIONS[ATTRIBUTES.PRINT_ASSIGNMENT],
    name: 'print_assignment_options',
    options: ({ metadata }) => {
      return generateAssignmentOptions(metadata.third_party_partner.name);
    },
    encodeValue: true
  },
  {
    label: QUESTIONS[ATTRIBUTES.TRAY_DESIGN_ASSIGNMENT],
    name: 'tray_design_assignment_options',
    options: () => [
      {
        value: [ORTHOSELECT],
        label: ASSIGNMENT_LABELS[ORTHOSELECT]
      },
      {
        value: [IN_OFFICE],
        label: ASSIGNMENT_LABELS[IN_OFFICE]
      },
      {
        value: [ORTHOSELECT, IN_OFFICE],
        label: `Choose ${ASSIGNMENT_LABELS[ORTHOSELECT]} or ${ASSIGNMENT_LABELS[IN_OFFICE]}`
      }
    ],
    encodeValue: true
  },
  {
    name: 'insert_brackets_assignment',
    options: ({ data, metadata }) => {
      return assignmentOptions({ thirdPartyName: metadata.third_party_partner.name }).filter(
        option => {
          return (
            option.value === ASSIGNMENTS.IN_OFFICE ||
            data.print_assignment_options.indexOf(option.value) !== -1
          );
        }
      );
    },
    overrideValueFn: ({ currentValue, options }) => {
      if (options.find(({ value }) => currentValue === value) !== undefined) {
        return currentValue;
      }

      return options
        .filter(({ value }) => [ORTHOSELECT, THIRD_PARTY].indexOf(value) !== -1)
        .sort()[0].value;
    },
    onChangeHook: (current, changes) => {
      if (
        !changes.print_assignment_options ||
        changes.print_assignment_options.indexOf(current.insert_bracket_assignment) !== -1
      ) {
        return changes;
      }
      return { ...changes, insert_bracket_assignment: changes.print_assignment_options[0] };
    }
  },
  {
    name: 'shipping_location_id',
    options: ({ metadata: { locations } }) => {
      return locations.map(({ id: value, name: label }) => ({ value, label }));
    }
  },
  {
    name: 'shipping_method',
    options: ({ metadata }) => {
      const { shipping_methods: methods } = metadata;

      return methods.map(({ name, label, estimated_time: [from, to] }) => {
        const daysLabel =
          from !== null
            ? ` (${from === to ? from : `${from} - ${to}`} day${from > 1 ? 's' : ''})`
            : '';
        return {
          value: name,
          label: `${label}${daysLabel}`
        };
      });
    }
  },
  {
    label: 'Show root integration?',
    name: 'root_integration',
    options: () => {
      return [
        { value: false, label: 'No' },
        { value: true, label: 'Yes' }
      ];
    }
  },
  {
    label: 'Use Default Shipping Methods for 3PP?',
    name: 'use_default_shipping_methods_for_3pp',
    when: (data, metadata) => {
      return metadata.third_party_partner !== null && metadata.third_party_partner.name !== null;
    },
    options: () => {
      return [
        { value: false, label: 'No' },
        { value: true, label: 'Yes' }
      ];
    }
  },
  {
    name: 'bonding_date',
    options: () => {
      return [
        { value: false, label: 'No' },
        { value: true, label: 'Yes' }
      ];
    }
  },
  {
    label: 'Is Archwire selection enabled?',
    name: 'arch_wires',
    options: () => {
      return [
        { value: false, label: 'No' },
        { value: true, label: 'Yes' }
      ];
    }
  },
  {
    label: QUESTIONS[ATTRIBUTES.ALLOWED_DIBS_OPTIONS],
    name: 'allowed_dibs_options',
    options: () => {
      return generateAllowedDIBSOptions();
    },
    encodeValue: true
  },
  {
    label: QUESTIONS[ATTRIBUTES.ARCH_WIRE_OPTIONS],
    name: ATTRIBUTES.ARCH_WIRE_OPTIONS,
    options: ({ metadata }) => {
      const { arch_wires: archWires } = metadata;

      return archWires.map(({ label, id }) => {
        return {
          value: id,
          label
        };
      });
    },
    multiple: true,
    placeholder: 'All the options',
    placeholderFn: metadata => {
      return metadata.has_uploaded_arch_wires ? 'Uploaded archwire found in the library' : null;
    },
    isDisabled: (data, metadata) => metadata.has_uploaded_arch_wires,
    formatValue: ({ value, metadata }) => {
      if (metadata.has_uploaded_arch_wires) {
        return [];
      }
      return value;
    }
  },
  {
    label: "Is 'Save for later' option enabled?",
    name: 'save_for_later_enabled',
    options: () => {
      return [
        { value: false, label: 'No' },
        { value: true, label: 'Yes' }
      ];
    }
  }
];
