import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDispatch } from 'react-redux';
import { JAWS_TYPES, PATIENT_FILES_IDS } from '../../../../constants';
import UploadFilesDialog from '../../../../components/UploadFilesDialog';
import PatientFilesApiService from '../../../../services/api/patientFiles';
import { enqueueNotification } from '../../../../reducers/notifications';

const useStyles = makeStyles(theme => {
  return {
    button: {
      width: '100%',
      maxHeight: theme.spacing(5),
      marginTop: theme.spacing(2)
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  };
});

const Item = ({ type, fileId, onDownload, onDelete, onUpload }) => {
  const classes = useStyles();
  const name = JAWS_TYPES[type].name.toUpperCase().slice(0, 3);

  if (fileId) {
    return (
      <>
        <Button
          key={`${name}_download`}
          variant="contained"
          onClick={onDownload}
          size="small"
          startIcon={<CloudDownloadIcon />}
          className={classes.button}
        >
          DOWNLOAD {name}
        </Button>
        <Button
          key={`${name}_upload`}
          variant="contained"
          color="secondary"
          onClick={onDelete}
          size="small"
          startIcon={<DeleteIcon />}
          className={classes.button}
        >
          Remove {name}
        </Button>
      </>
    );
  }

  return (
    <Button
      key={`upload_${name}`}
      variant="contained"
      onClick={onUpload}
      size="small"
      startIcon={<CloudUploadIcon />}
      className={classes.button}
    >
      Upload {name}
    </Button>
  );
};

Item.propTypes = {
  type: PropTypes.number.isRequired,
  fileId: PropTypes.number,
  onDownload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired
};

Item.defaultProps = {
  fileId: null
};

const TrayFilesManager = ({ patientId, jaws, onDelete, onUpload, disabled }) => {
  const [archType, setArchType] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = uploadedFiles => {
    const file = uploadedFiles[Object.keys(uploadedFiles)[0]];
    if (file) {
      onUpload(archType, file);
    }
    setArchType(null);
  };

  const uploadHandler = jaw => () => {
    if (disabled) {
      return;
    }
    setArchType(jaw);
  };

  const deleteHandler = ({ jaw, fileId }) => () => {
    const patientFilesService = new PatientFilesApiService();
    patientFilesService
      .deleteFile(fileId)
      .then(() => {
        onDelete(jaw, fileId);
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };

  const downloadHandler = fileId => () => {
    const apiService = new PatientFilesApiService();
    return apiService
      .getDownloadUrl(fileId)
      .then(response => {
        window.open(response.url);
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };

  return (
    <div className={classes.container}>
      {jaws.map(jaw => (
        <Item
          key={jaw.type}
          {...jaw}
          onDelete={deleteHandler(jaw)}
          onDownload={downloadHandler(jaw.fileId)}
          onUpload={uploadHandler(jaw.type)}
        />
      ))}
      <UploadFilesDialog
        title="Upload Tray"
        patientFileTypeId={PATIENT_FILES_IDS.stl}
        patientId={patientId}
        maxFiles={1}
        handleClose={handleClose}
        open={archType !== null}
      />
    </div>
  );
};

TrayFilesManager.propTypes = {
  patientId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  jaws: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.number.isRequired,
      fileId: PropTypes.number
    })
  ).isRequired,
  onUpload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

TrayFilesManager.defaultProps = {
  disabled: false
};

export default TrayFilesManager;
