import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Select } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import { isEqual } from 'lodash';
import { REQUEST, REQUEST_STATUS } from '../../../constants';
import ButtonLink from '../../ButtonLink';

const STATUS_LABELS = {
  [REQUEST_STATUS.NEW]: 'New',
  [REQUEST_STATUS.IN_PROGRESS]: 'In Progress',
  [REQUEST_STATUS.PENDING]: 'Pending',
  [REQUEST_STATUS.COMPLETED]: 'Completed'
};

const useStyles = makeStyles(() => {
  return {
    filterTitle: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    clearButton: {
      float: 'right',
      alignSelf: 'center',
      fontSize: 12,
      color: 'gray'
    }
  };
});

const parseInitialValue = rawValue => {
  if (!rawValue || rawValue.length === 0) {
    return {
      request: null,
      statuses: []
    };
  }
  const [request] = rawValue[0].split('.');
  return { request, statuses: rawValue.map(item => item.split('.')[1]) };
};

const RequestsStatusFilter = ({ value: rawValue, onChange }) => {
  const value = useMemo(() => {
    return parseInitialValue(rawValue);
  }, [rawValue]);

  const { Option } = Select;
  const classes = useStyles();

  const requestsConfig = {
    [REQUEST.INITIAL_CASE_SETUP]: {
      title: 'Initial Case Setup',
      statuses: [
        REQUEST_STATUS.NEW,
        REQUEST_STATUS.IN_PROGRESS,
        REQUEST_STATUS.PENDING,
        REQUEST_STATUS.COMPLETED
      ]
    },
    [REQUEST.FLAWED_TRAY]: {
      title: 'Flawed Tray(s) Correction',
      statuses: [REQUEST_STATUS.NEW, REQUEST_STATUS.IN_PROGRESS, REQUEST_STATUS.COMPLETED]
    },
    [REQUEST.PRINT]: {
      title: 'Printing Request',
      statuses: [REQUEST_STATUS.NEW, REQUEST_STATUS.IN_PROGRESS, REQUEST_STATUS.COMPLETED]
    },
    [REQUEST.TRAY_DESIGN]: {
      title: 'Tray Design',
      statuses: [REQUEST_STATUS.NEW, REQUEST_STATUS.IN_PROGRESS, REQUEST_STATUS.COMPLETED]
    }
  };

  const triggerOnChange = newValue => {
    if (newValue.request === null) {
      onChange([]);
      return;
    }

    const updateValue = newValue;

    if (newValue.statuses.length === 0) {
      updateValue.statuses = requestsConfig[newValue.request].statuses;
    }

    const statuses =
      updateValue.statuses.length === 0
        ? requestsConfig[updateValue.request].statuses
        : updateValue.statuses;
    const filterValues = statuses.map(status => `${updateValue.request}.${status}`);

    onChange(filterValues);
  };

  const onStatusesChange = statuses => {
    const newValue = {
      ...value,
      statuses
    };
    triggerOnChange(newValue);
  };

  const getDefaultStatuses = request => {
    return requestsConfig[request].statuses.filter(
      status => status !== REQUEST_STATUS.COMPLETED && status !== REQUEST_STATUS.PENDING
    );
  };

  const onRequestChange = request => {
    let newValue;
    if (request === null) {
      newValue = {
        request: null,
        statuses: []
      };
    } else {
      newValue = {
        request,
        statuses: getDefaultStatuses(request) // reset
      };
    }
    triggerOnChange(newValue);
  };

  const resetRequest = e => {
    e.preventDefault();
    onRequestChange(null);
  };

  const resetStatues = e => {
    e.preventDefault();
    onStatusesChange(getDefaultStatuses(value.request));
  };

  const availableStatuses = value.request ? requestsConfig[value.request].statuses : [];

  return (
    <>
      <Grid item xs={4}>
        <Typography component="div" className={classes.filterTitle} variant="subtitle1">
          Filter Request
          {value.request !== null && (
            <ButtonLink type="button" className={classes.clearButton} onClick={resetRequest}>
              Clear
            </ButtonLink>
          )}
        </Typography>
        <Select
          placeholder="Select Request"
          value={value.request}
          style={{ width: '100%' }}
          onChange={onRequestChange}
        >
          {Object.keys(requestsConfig).map(name => {
            return (
              <Option value={name} key={name}>
                {requestsConfig[name].title}
              </Option>
            );
          })}
        </Select>
      </Grid>
      <Grid item xs={4}>
        <Typography component="div" className={classes.filterTitle} variant="subtitle1">
          Filter Request Status
          {value.request && !isEqual(getDefaultStatuses(value.request), value.statuses) && (
            <ButtonLink className={classes.clearButton} onClick={resetStatues}>
              Reset
            </ButtonLink>
          )}
        </Typography>
        <Select
          mode="multiple"
          disabled={value.request === null}
          placeholder="Select specific status"
          style={{ width: '100%' }}
          value={value.statuses}
          onChange={onStatusesChange}
        >
          {availableStatuses.map(status => {
            return (
              <Option value={status} key={status}>
                {STATUS_LABELS[status]}
              </Option>
            );
          })}
        </Select>
      </Grid>
    </>
  );
};

RequestsStatusFilter.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired
};

export default RequestsStatusFilter;
