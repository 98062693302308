import React from 'react';
import {
  ASSIGNMENT_LABELS,
  ASSIGNMENTS,
  ATTRIBUTES,
  EXPORTS,
  SCAN_SUBMISSION_LABELS,
  SCAN_SUBMISSION_METHODS,
  SCANNER_LABELS
} from '../constants';

export default (rxForm, preference, styles) => {
  return [
    {
      attribute: ATTRIBUTES.DOCTOR,
      generateLabel: (value, data) => {
        return data.user.full_name;
      }
    },
    {
      attribute: ATTRIBUTES.INTRAORAL_SCAN_DATE
    },
    {
      attribute: ATTRIBUTES.BONDING_DATE,
      hide: !rxForm.bonding_date
    },
    {
      attribute: ATTRIBUTES.TREATMENT_LOCATION,
      generateLabel: (value, data) => {
        return data.location.location_name;
      }
    },
    {
      attribute: ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT
    },
    {
      attribute: ATTRIBUTES.RUSH_CASE,
      generateLabel: (value, data) => {
        if (!value) {
          return 'No';
        }
        const authorizedName = data[ATTRIBUTES.RUSH_CASE_AUTHORIZED_BY_NAME];
        return `Yes, authorized by "${authorizedName}"`;
      },
      hide: !rxForm.version
    },
    {
      attribute: ATTRIBUTES.BRACKET_LIBRARIES,
      generateLabel: value => {
        return (
          <ol className={styles.bracketsList}>
            {value.map(library => (
              <li key={library.file_name}>{library.file_name}</li>
            ))}
          </ol>
        );
      },
      labelProps: { component: 'div' },
      hide:
        !rxForm.version &&
        Array.isArray(rxForm.bracket_libraries) &&
        rxForm.bracket_libraries.length > 0
    },
    {
      attribute: ATTRIBUTES.ARCH_WIRES,
      generateLabel: value => {
        return (
          <ol className={styles.bracketsList}>
            {value.map(archWire => (
              <li key={archWire.name}>{archWire.name}</li>
            ))}
          </ol>
        );
      },
      labelProps: { component: 'div' },
      hide: !rxForm.version || !Array.isArray(rxForm.arch_wires) || rxForm.arch_wires.length === 0
    },
    {
      attribute: ATTRIBUTES.SCAN_SUBMISSION_METHOD,
      generateLabel: (value, data) => {
        let label = SCAN_SUBMISSION_LABELS[value];
        if (value === SCAN_SUBMISSION_METHODS.UPLOAD) {
          label = SCAN_SUBMISSION_LABELS[value];
        } else {
          const scannerName =
            data[ATTRIBUTES.CUSTOM_SCANNER_NAME] || SCANNER_LABELS[data[ATTRIBUTES.SCANNER]];
          label = `${label} (${scannerName})`;
        }
        return label.replace(
          '{ASSIGNMENT}',
          ASSIGNMENT_LABELS[data[ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT]]
        );
      }
    },
    {
      attribute: ATTRIBUTES.ARCH_TYPE,
      hide: !rxForm.version
    },
    {
      attribute: ATTRIBUTES.EXPORT_TYPE,
      hide: !rxForm.version
    },
    {
      attribute: ATTRIBUTES.PRINT_ASSIGNMENT,
      hide: !rxForm.version
    },
    {
      attribute: ATTRIBUTES.TRAY_DESIGN_ASSIGNMENT,
      hide:
        !rxForm.version ||
        rxForm.print_assignment !== ASSIGNMENTS.IN_OFFICE ||
        rxForm.export_type !== EXPORTS.TRAYS
    },
    {
      attribute: ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT,
      hide: !rxForm.version
    },
    {
      attribute: ATTRIBUTES.SHIPPING_LOCATION,
      generateLabel: (value, data) => {
        return data.shipping_location && data.shipping_location.location_name;
      },
      hide: !rxForm.version
    },
    {
      attribute: ATTRIBUTES.SHIPPING_METHOD,
      hide: !rxForm.version || rxForm.print_assignment === ASSIGNMENTS.IN_OFFICE,
      generateLabel: (value, data) => {
        return data.shipping_method_label;
      }
    },
    {
      attribute: ATTRIBUTES.ROOT_INTEGRATION_TYPE,
      hide: !rxForm.version
    },
    {
      attribute: 'created_by_user_id',
      hide: rxForm.user_id === rxForm.created_by_user_id,
      generateLabel: (value, data) => {
        return data.created_by_user.full_name;
      }
    },
    {
      attribute: ATTRIBUTES.NOTES
    },
    {
      attribute: ATTRIBUTES.CUSTOM_SETUP,
      generateLabel: value => (value ? 'Yes' : 'No'),
      hide:
        !rxForm[ATTRIBUTES.CUSTOM_SETUP] === null ||
        !rxForm.version ||
        !preference.custom_setup_enabled
    }
  ];
};
