export const PATIENT_FILES_IDS = {
  ios: 1,
  stl: 2,
  photo: 3,
  xray: 4
};

export const ROLES = {
  superAdmin: 'super_admin',
  admin: 'admin',
  doctor: 'doctor',
  techSupport: 'tech_support',
  supervisor: 'supervisor',
  organizationOwner: 'organization_owner',
  organizationMember: 'organization_member',
  organizationManager: 'organization_manager',
  labPartner: 'lab_partner'
};

export const MANAGEMENT_ROLES = [
  ROLES.superAdmin,
  ROLES.admin,
  ROLES.techSupport,
  ROLES.labPartner
];

export const ROLE_OPTIONS = {
  super_admin: { key: 'super_admin', label: 'Super Admin' },
  admin: { key: 'admin', label: 'Admin' },
  tech_support: { key: 'tech_support', label: 'Technician' },
  supervisor: { key: 'supervisor', label: 'Supervisor' },
  lab_partner: { key: 'lab_partner', label: '3rd Party Partner' },
  doctor: { key: 'doctor', label: 'Doctor' }
};

export const ALLOWED_ROLES = {
  admin: { key: 'admin', label: 'Admin' },
  tech_support: { key: 'tech_support', label: 'Technician' },
  supervisor: { key: 'supervisor', label: 'Supervisor' },
  lab_partner: { key: 'lab_partner', label: '3rd Party Partner' },
  doctor: { key: 'doctor', label: 'Doctor' }
};

export const ORGANIZATION_ROLE_OPTIONS = {
  organization_owner: {
    key: 'organization_owner',
    label: 'Organization Owner',
    shortLabel: 'Owner'
  },
  organization_member: {
    key: 'organization_member',
    label: 'Organization Member',
    shortLabel: 'Member'
  },
  organization_manager: {
    key: 'organization_manager',
    label: 'Organization Manager',
    shortLabel: 'Manager'
  }
};

export const JAWS_TYPES = {
  1: { name: 'Maxillary' },
  2: { name: 'Mandibular' },
  3: { name: 'Both' }
};

export const JAWS_TYPES_IDS = {
  maxillary: 1,
  mandibular: 2,
  both: 3
};

export const CONTENT_TYPES = {
  1: { name: 'IDB Tray' },
  2: { name: 'Model w/ Guide' },
  3: { name: 'Model w/ Bracket' },
  4: { name: 'Aligner' }
};

export const PROMOTION_CODE_TYPES = {
  1: { name: 'Initial Setup', color: 'blue' },
  2: { name: 'First Month Free', color: 'amber' }
};

export const NOT_ALLOWED_PROMOTION_CODE_TYPES = [2];

export const PATIENT_STATUSES = {
  1: { name: 'Active' },
  3: { name: 'Complete' }
};

export const PATIENT_STATUSES_VALUE = {
  ACTIVE: 1,
  COMPLETED: 3
};

export const NEW_PATIENT_STATUSES = {
  new: { name: 'New', order: 1 },
  in_progress: { name: 'In Progress', order: 2 },
  awaiting_approval: { name: 'Awaiting Approval', order: 3 },
  approved: { name: 'Approved', order: 4 },
  completed: { name: 'Completed', order: 5 },
  all: { name: 'Total', order: 6 }
};

export const RX_FORMS = {
  submission_completed_by: {
    1: { value: 'OrthoSelect' },
    2: { value: 'In-office' },
    3: { value: 'Authorized 3rd Party' }
  },
  scan_submission_type: {
    1: { value: 'I am uploading STL files directly to this form' },
    2: { value: 'I am sending scans directly to OrthoSelect from my intraoral scanner' }
  },
  scanner_type: {
    1: { label: 'iTero', value: 'itero', id: 1 },
    2: { label: '3Shape', value: '3shape', id: 2 },
    3: { label: '3M', value: '3m', id: 3 },
    4: { label: 'Carestream', value: 'carestream', id: 4 },
    5: { label: 'Cerec', value: 'cerec', id: 5 },
    6: { label: 'Medit', value: 'medit', id: 6 },
    7: { label: 'Other', value: 'other', id: 7 }
  },
  jaws_id: {
    1: { name: 'Maxillary' },
    2: { name: 'Mandibular' },
    3: { name: 'Both' }
  }
};

export const PRINT_REQUEST_TABLE_CONFIG = [
  { title: 'Nº', dataIndex: 'step', width: '6%' },
  { title: 'Process', dataIndex: 'process', width: '18%' },
  {
    title: 'Tech Name',
    dataIndex: 'tech_name',
    width: '24%',
    editable: true,
    type: 'user',
    deletable: true
  },
  {
    title: 'Date',
    dataIndex: 'date',
    width: '24%',
    type: 'date',
    editable: true
  },
  {
    title: 'ST',
    dataIndex: 'time_start',
    width: '14%',
    type: 'time',
    editable: true
  },
  {
    title: 'ET',
    dataIndex: 'time_end',
    width: '14%',
    type: 'time',
    editable: true
  }
];

export const DEFAULT_TECH_WORKFLOW = [
  {
    id: 1,
    process: 'S1-5',
    tech_name: '',
    date: '',
    time_start: '',
    time_end: ''
  },
  {
    id: 2,
    process: 'QA',
    tech_name: '',
    date: '',
    time_start: '',
    time_end: ''
  }
];

export const REQUEST_STATUS = {
  NEW: 'NEW',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED'
};

export const REQUEST = {
  INITIAL_CASE_SETUP: 'initial',
  FLAWED_TRAY: 'flawed',
  PRINT: 'print',
  TRAY_DESIGN: 'tray_design'
};

export const REQUESTS_FILTER = [
  {
    title: 'Initial Setup',
    value: 'initial',
    children: [
      {
        title: 'New',
        value: 'initial.NEW'
      },
      {
        title: 'In Progress',
        value: 'initial.IN_PROGRESS'
      },
      {
        title: 'Pending',
        value: 'initial.PENDING'
      },
      {
        title: 'Complete',
        value: 'initial.COMPLETED'
      }
    ]
  },
  {
    title: 'Flawed',
    value: 'flawed',
    children: [
      {
        title: 'New',
        value: 'flawed.NEW'
      },
      {
        title: 'In Progress',
        value: 'flawed.IN_PROGRESS'
      },
      {
        title: 'Complete',
        value: 'flawed.COMPLETED'
      }
    ]
  },
  {
    title: 'Print',
    value: 'print',
    children: [
      {
        title: 'New',
        value: 'print.NEW'
      },
      {
        title: 'In Progress',
        value: 'print.IN_PROGRESS'
      },
      {
        title: 'Complete',
        value: 'print.COMPLETED'
      }
    ]
  },
  {
    title: 'Tray Design',
    value: 'tray_design',
    children: [
      {
        title: 'New',
        value: 'print.NEW'
      },
      {
        title: 'In Progress',
        value: 'print.IN_PROGRESS'
      },
      {
        title: 'Complete',
        value: 'print.COMPLETED'
      }
    ]
  }
];

export const REQUEST_STEPS = {
  [REQUEST.INITIAL_CASE_SETUP]: ['S1-5', 'QA'],
  [REQUEST.FLAWED_TRAY]: ['CFT', 'QA'],
  [REQUEST.PRINT]: [
    'Generate Tray',
    'Generate QA',
    'Build Print Job',
    'Print/Process/Sort',
    'Insert Brackets',
    'Insert QA',
    'Ship QA',
    'Ship'
  ],
  [REQUEST.TRAY_DESIGN]: ['Generate Tray', 'Generate QA']
};

export const FILE_TYPE_IDS = {
  ios: 1,
  stl: 2,
  photos: 3,
  xray: 4,
  brackets: 7,
  dicom: 8,
  other: 9
};

export const RX_FORM_STATUSES = {
  1: 'New',
  2: 'In Progress',
  3: 'Pending',
  4: 'Completed',
  5: 'Canceled'
};

export const RX_FORM_STATUSES_ID = {
  new: 1,
  in_progress: 2,
  pending: 3,
  completed: 4,
  canceled: 5
};

export const REQUEST_STATUSES = {
  1: 'New',
  2: 'Completed',
  3: 'Canceled'
};

export const REQUEST_STATUSES_ID = {
  new: 1,
  completed: 2,
  canceled: 3
};

export const FLAWED_TRAYS_STATUSES = {
  1: 'New',
  2: 'In Progress',
  3: 'Completed',
  4: 'Canceled'
};

export const FLAWED_TRAYS_STATUSES_ID = {
  new: 1,
  in_progress: 2,
  completed: 3,
  canceled: 4
};

export const PRINTING_REQUESTS_STATUSES = {
  1: 'New',
  4: 'In Progress',
  2: 'Completed',
  3: 'Canceled'
};

export const PRINTING_REQUESTS_STATUSES_ID = {
  new: 1,
  in_progress: 4,
  completed: 2,
  canceled: 3
};

export const INITIAL_SETUP_REQUEST_WORKFLOW = [];

export const UI_SETTINGS = {
  formWidth: 0.75
};

export const REQUEST_ASSIGNMENT_ORTHOSELECT = 1;
export const REQUEST_ASSIGNMENT_IN_OFFICE = 2;
export const REQUEST_ASSIGNMENT_THIRD_PARTY = 3;

export const REQUEST_ASSIGNMENT_ORTHOSELECT_LABEL = 'OrthoSelect';
export const REQUEST_ASSIGNMENT_IN_OFFICE_LABEL = 'In-office';
export const REQUEST_ASSIGNMENT_THIRD_PARTY_LABEL = '3rd Party Partner';

export const PATIENT_REQUEST_ASSIGNMENT_FILTER = {
  ORTHOSELECT: { value: REQUEST_ASSIGNMENT_ORTHOSELECT, label: 'OrthoSelect' },
  IN_OFFICE: { value: REQUEST_ASSIGNMENT_IN_OFFICE, label: 'In-office' },
  THIRD_PARTY: { value: REQUEST_ASSIGNMENT_THIRD_PARTY, label: '3rd Party Partner' }
};

export const INITIAL_SETUP_ASSIGNED_TO = {
  1: { letter: 'O', name: 'OrthoSelect' },
  2: { letter: 'H', name: 'In-office' },
  3: { letter: '3', name: '3rd Party Partner' }
};

export const PRINT_ASSIGNED_TO = {
  1: { letter: 'O', name: 'OrthoSelect' },
  3: { letter: '3', name: '3rd Party Partner' }
};

export const PRINT_ASSIGNED_TO_ID = {
  orthoselect: REQUEST_ASSIGNMENT_ORTHOSELECT,
  thirdParty: REQUEST_ASSIGNMENT_THIRD_PARTY
};

export const INITIAL_SETUP_ASSIGNED_TO_ID = {
  orthoselect: REQUEST_ASSIGNMENT_ORTHOSELECT,
  thirdParty: REQUEST_ASSIGNMENT_THIRD_PARTY
};

export const PAYMENT_STATUS = {
  PAID: { key: 'PAID', label: 'Paid' },
  NOT_PAID: { key: 'NOT_PAID', label: 'Not Paid' },
  BLOCKED: { key: 'BLOCKED', label: 'Blocked' },
  PENDING: { key: 'PENDING', label: 'Pending' },
  SUSPENDED: { key: 'SUSPENDED', label: 'Suspended' }
};

export const ONBOARDING_STATUS = {
  PENDING: { key: '1', label: 'Pending' },
  IN_PROGRESS: { key: '5', label: 'In Progress' },
  COMPLETED: { key: '10', label: 'Completed' }
};

export const ACCEPTED_FILE_TYPES = {
  [FILE_TYPE_IDS.stl]:
    'application/sla, model/stl, model/x.stl-ascii, model/x.stl-binary, .stl, .zip',
  [FILE_TYPE_IDS.photos]: 'image/*, application/pdf, .zip, .jpg, .jpeg, .png, .gif, .pdf',
  [FILE_TYPE_IDS.dicom]: '.zip',
  [FILE_TYPE_IDS.other]: '*'
};

export const ZIP_EXTRACTABLE_FILE_TYPES = {
  [FILE_TYPE_IDS.stl]: ['.stl'],
  [FILE_TYPE_IDS.photos]: ['.jpg', '.jpeg', '.png', '.gif', '.pdf']
};

export const UPLOAD_NOTES = {
  [FILE_TYPE_IDS.dicom]: 'Files must first be zipped'
};

export const BRACKET_FORMATS = {
  ORIGINAL: 1,
  VTK: 2
};
