import { enqueueNotification } from './notifications';
import TechWorkflowProcessApiService from '../services/api/techWorkflowProcess';
import { formatProcesses, formatProcessRequest } from '../helpers/techWorkflow';
import TechWorkflowApiService from '../services/api/techWorkflow';

export const FETCH_TECH_WORKFLOW_REQUEST_ACTION = 'FETCH_TECH_WORKFLOW_REQUEST_ACTION';
export const FETCH_TECH_WORKFLOW_REQUEST_SUCCESS = 'FETCH_TECH_WORKFLOW_REQUEST_SUCCESS';

export const SAVE_TECH_WORKFLOW_PROCESSES_REQUEST_ACTION =
  'SAVE_TECH_WORKFLOW_PROCESSES_REQUEST_ACTION';
export const SAVE_TECH_WORKFLOW_PROCESSES_SUCCESS_ACTION =
  'SAVE_TECH_WORKFLOW_PROCESSES_SUCCESS_ACTION';

const typeInitialState = {
  fetching: false,
  saving: false,
  data: null
};

const initialState = {};

export default (state = initialState, action) => {
  const type = action.payload?.type || 'default';
  const requestState = state[type] || typeInitialState;

  switch (action.type) {
    case FETCH_TECH_WORKFLOW_REQUEST_ACTION:
      return {
        ...state,
        [type]: {
          ...requestState,
          fetching: true,
          data: null
        }
      };
    case FETCH_TECH_WORKFLOW_REQUEST_SUCCESS:
      return {
        ...state,
        [type]: {
          ...requestState,
          fetching: false,
          data: {
            ...action.payload.data,
            processes: formatProcesses(action.payload.data.processes)
          }
        }
      };
    case SAVE_TECH_WORKFLOW_PROCESSES_REQUEST_ACTION:
      return {
        ...state,
        [type]: {
          ...requestState,
          saving: true
        }
      };
    default:
      return state;
  }
};

export function fetchTechWorkflowAction(id, type = null) {
  return dispatch => {
    dispatch({ type: FETCH_TECH_WORKFLOW_REQUEST_ACTION, payload: { type } });
    const apiService = new TechWorkflowApiService();
    return apiService
      .retrieve(id)
      .then(response => {
        dispatch({
          type: FETCH_TECH_WORKFLOW_REQUEST_SUCCESS,
          payload: { data: response.data, type }
        });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function saveTechWorkflowProcessesAction(processId, data, type = null) {
  return dispatch => {
    dispatch({ type: SAVE_TECH_WORKFLOW_PROCESSES_REQUEST_ACTION, payload: { type } });

    return new Promise((resolve, reject) => {
      const apiService = new TechWorkflowProcessApiService();
      return apiService
        .update(processId, formatProcessRequest(data))
        .then(response => {
          dispatch({ type: SAVE_TECH_WORKFLOW_PROCESSES_SUCCESS_ACTION, payload: { type } });
          dispatch({
            type: FETCH_TECH_WORKFLOW_REQUEST_SUCCESS,
            payload: { data: response.tech_workflow, type }
          });

          dispatch(enqueueNotification('success', response.message));
          resolve(response);
        })
        .catch(error => {
          dispatch(enqueueNotification('error', error.message));
          reject(error);
        });
    });
  };
}

export function startTechWorkflowProcessAction(techProcessId, type = null) {
  return dispatch => {
    dispatch({ type: SAVE_TECH_WORKFLOW_PROCESSES_REQUEST_ACTION, payload: { type } });
    return new Promise((resolve, reject) => {
      const apiService = new TechWorkflowProcessApiService();
      return apiService
        .start(techProcessId)
        .then(response => {
          dispatch({ type: SAVE_TECH_WORKFLOW_PROCESSES_SUCCESS_ACTION, payload: { type } });
          dispatch({
            type: FETCH_TECH_WORKFLOW_REQUEST_SUCCESS,
            payload: { data: response.tech_workflow, type }
          });

          dispatch(enqueueNotification('success', response.message));
          resolve(response);
        })
        .catch(error => {
          dispatch(enqueueNotification('error', error));
          reject(error);
        });
    });
  };
}

export function finishTechWorkflowProcessAction(techProcessId, type = null) {
  return dispatch => {
    dispatch({ type: SAVE_TECH_WORKFLOW_PROCESSES_REQUEST_ACTION, payload: { type } });

    return new Promise((resolve, reject) => {
      const apiService = new TechWorkflowProcessApiService();
      return apiService
        .finish(techProcessId)
        .then(response => {
          dispatch({ type: SAVE_TECH_WORKFLOW_PROCESSES_SUCCESS_ACTION, payload: { type } });
          dispatch({
            type: FETCH_TECH_WORKFLOW_REQUEST_SUCCESS,
            payload: { data: response.tech_workflow, type }
          });

          dispatch(enqueueNotification('success', response.message));
          resolve(response);
        })
        .catch(error => {
          dispatch(enqueueNotification('error', error));
          reject(error);
        });
    });
  };
}
