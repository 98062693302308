import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import BuildIcon from '@material-ui/icons/Build';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import StatusTag from '../../../../components/StatusTag';
import { JAWS_TYPES } from '../../../../constants';

const Item = ({ type, hasPrint }) => {
  const title = JAWS_TYPES[type].name;

  return (
    <Grid container alignItems="center" justifyContent="space-between" key={`${type}`}>
      <Grid item>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar>
              <BuildIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={title}
            disableTypography
            secondary={
              <div>
                <Typography component="span" variant="body2" color="textPrimary">
                  IDB Tray
                </Typography>
              </div>
            }
          />
        </ListItem>
      </Grid>
      {hasPrint && (
        <Grid item>
          <div style={{ margin: '4px 0' }}>
            <StatusTag label="Print" color="orange" />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

Item.propTypes = {
  type: PropTypes.number.isRequired,
  hasPrint: PropTypes.bool
};

Item.defaultProps = {
  hasPrint: false
};

const JawLabels = ({ jaws }) => {
  return (
    <>
      {jaws.map(jaw => (
        <Item key={jaw.type} {...jaw} />
      ))}
    </>
  );
};

JawLabels.propTypes = {
  jaws: PropTypes.arrayOf(PropTypes.shape(Item.propTypes)).isRequired
};

export default JawLabels;
