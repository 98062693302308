import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TechWorkflow from './TechWorkflow';
import LoadingIndicator from '../LoadingIndicator';
import {
  fetchTechWorkflowAction,
  finishTechWorkflowProcessAction,
  saveTechWorkflowProcessesAction,
  startTechWorkflowProcessAction
} from '../../reducers/techWorkflows';

const Manager = ({ fetching, fetch, save, start, finish, data, readOnly }) => {
  const handleSave = (updated, old) => {
    return save(old.id, updated);
  };
  useEffect(() => {
    if (fetching) {
      return;
    }
    if (!data) {
      fetch();
    }
  }, [data, fetching, fetch]);

  if (!data) {
    return <LoadingIndicator />;
  }

  return (
    <TechWorkflow
      workflowData={data.processes}
      save={handleSave}
      start={start}
      finish={finish}
      readOnly={readOnly}
    />
  );
};

Manager.propTypes = {
  fetching: PropTypes.bool.isRequired,
  fetch: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  start: PropTypes.func.isRequired,
  finish: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object
};

const Wrapper = connect(
  (state, { id, type }) => {
    const { fetching = false, saving = false, data = null } = state.techWorkflows[type] || {};

    return {
      data: !!data && data.id === id ? data : null,
      fetching,
      saving
    };
  },
  (dispatch, props) => {
    const { afterUpdate, type } = props;
    return {
      fetch: () => dispatch(fetchTechWorkflowAction(props.id, type)),
      save: (processId, data) =>
        dispatch(saveTechWorkflowProcessesAction(processId, data, type)).then(afterUpdate),
      start: processId =>
        dispatch(startTechWorkflowProcessAction(processId, type)).then(afterUpdate),
      finish: processId =>
        dispatch(finishTechWorkflowProcessAction(processId, type)).then(afterUpdate)
    };
  }
)(Manager);

Wrapper.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired
};

export default Wrapper;
