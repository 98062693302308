import TrayDesignRequestApiService from '../services/api/trayDesignRequests';
import { enqueueNotification } from './notifications';

const TRAY_DESIGN_REQUEST_FETCH_ITEM = 'TRAY_DESIGN_REQUEST_FETCH_ITEM';
const TRAY_DESIGN_REQUEST_FETCHED_ITEM = 'TRAY_DESIGN_REQUEST_FETCHED_ITEM';

const TRAY_DESIGN_REQUEST_UPDATE_ITEM = 'TRAY_DESIGN_REQUEST_UPDATE_ITEM';
const TRAY_DESIGN_REQUEST_UPDATED_ITEM = 'TRAY_DESIGN_REQUEST_UPDATED_ITEM';

const initialState = {
  current: {
    fetching: false,
    updating: false,
    data: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TRAY_DESIGN_REQUEST_FETCH_ITEM: {
      return {
        ...state,
        current: {
          ...state.current,
          fetching: true
        }
      };
    }
    case TRAY_DESIGN_REQUEST_FETCHED_ITEM: {
      return {
        ...state,
        current: {
          ...state.current,
          fetching: false,
          data: action.payload
        }
      };
    }
    case TRAY_DESIGN_REQUEST_UPDATE_ITEM: {
      return {
        ...state,
        current: {
          ...state.current,
          updating: true
        }
      };
    }
    case TRAY_DESIGN_REQUEST_UPDATED_ITEM: {
      return {
        ...state,
        current: {
          ...state.current,
          updating: false,
          data: action.payload
        }
      };
    }
    default:
      return state;
  }
};

export function fetchTrayDesignRequestAction(id) {
  return dispatch => {
    dispatch({ type: TRAY_DESIGN_REQUEST_FETCH_ITEM });
    const trayDesignRequestApiService = new TrayDesignRequestApiService();
    return trayDesignRequestApiService
      .get(id)
      .then(response => {
        dispatch({ type: TRAY_DESIGN_REQUEST_FETCHED_ITEM, payload: response.data });
      })
      .catch(error => {
        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function updateTrayDesignRequestAction(id, data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({ type: TRAY_DESIGN_REQUEST_UPDATE_ITEM });
      const trayDesignRequestApiService = new TrayDesignRequestApiService();
      return trayDesignRequestApiService
        .update(id, data)
        .then(response => {
          dispatch({ type: TRAY_DESIGN_REQUEST_UPDATED_ITEM, payload: response.data });
          resolve(response);
        })
        .catch(error => {
          dispatch(enqueueNotification('error', error.message));
          reject(error);
        });
    });
  };
}
